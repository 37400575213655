var timeInterval = 5000;

$.when($.ready).then(function(){
    let slogansTitles = $('.slogans-titles');

    slogansTitles.each(function(id) {
        // Prepare data
        let slogan = $(slogansTitles[id]);
        let sloganTitle = slogan.text();
        let sloganTitleArray = sloganTitle.split(';');
        let counter = 1;

        // Print the first slogan
        slogan.removeClass('d-none');
        slogan.text(sloganTitleArray[0]);

        // Change slogan after several seconds defined with "timeInterval"
        if(sloganTitleArray.length > 1) {
            setInterval(function() {
                slogan.fadeOut(1000, function() {
                    // Update text
                    slogan.text(sloganTitleArray[counter]);
    
                    // Update index for the next text
                    counter += 1;
                    counter %= sloganTitleArray.length;
    
                    // Fade out effect
                    slogan.fadeIn(1000);
                });
            }, timeInterval);
        }
    });
})
