var consentElement = $('#cookies_consent_bar');
var consentButton = $('#consent_button');

$.when($.ready).then(function(){
    consentButton.click(function() {
        console.log("Click consent");
        $.ajax({
            url: consentElement.data('urlconsent'),
            method: 'GET',
            dataType: 'json',
            data: "",
            success: function(data){
                if (data['status'] == 'success') {
                    console.log('Consent :)');
                    consentElement.addClass('d-none');
                } else {
                    console.log("Cannot consent :(");
                }
            },
            error: function(error){
                console.log("An error occured");
                console.log(error);
            }
        });
    });
});
